import React from 'react';
import { ServerError } from 'layouts';

export const index = () => {
    return (
        <ServerError
            imageSrc={'/static/img/MessyDoodle.png'}
            title={'404 page not found'}
            description={`Don't be mad... But the page you are looking for doesn't exist.`}
            textContentPadding={4}
        />
    );
};

export default index;
